<template>
  <div
    class="wrapper--map"
    :class="{ 'closed--map': !showMap && $route.name !== 'search-user-listing' }"
    ref="mapLayout"
  >
    <client-only>
      <div class="map--container--fixed">
        <search-profile
          v-if="$route.name === 'search-user-listing'"
          v-show="$route.name !== 'search-user-listing' || $mq === 'xs' || showMap"
        ></search-profile>
        <div
          class="map--container"
          :class="{ 'map--with-profile': $route.name === 'search-user-listing' }"
          id="map"
          :style="'height: 100%'"
          v-show="$route.name !== 'search-user-listing' || showMap"
        ></div>
        <div
          class="wrapper--info"
          v-if="meta && meta.total > 0"
          v-show="$route.name !== 'search-user-listing' || showMap"
        >
          <span>{{
            $t('general.showing') +
              ' Listing no. ' +
              listingNumber +
              ' ' +
              $t('general.outOf') +
              ' ' +
              meta.total
          }}</span>
        </div>
        <card-listing
          @mouseleave="infoWindowLeave"
          @mouseenter="infoWindowEnter"
          :listings="listingData"
          ref="cardListing"
        />
        <SectionLoading :show="showMap && isLoading" />
        <!--    <client-only>-->
        <!--      <div class="wrapper&#45;&#45;switch">-->
        <!--        <div-->
        <!--          class="section&#45;&#45;switch position-absolute"-->
        <!--          :class="{ closed: !contentMaps }"-->
        <!--          @click="hideMaps"-->
        <!--        >-->
        <!--          <span class="ion-chevron-right mr-2 ml-2"></span>-->
        <!--          <span v-show="contentMaps">{{ $t('search.closeMap') }}</span>-->
        <!--          <span style="transform: scaleX(-1); text-align: right;" v-show="!contentMaps">{{-->
        <!--            $t('search.openMap')-->
        <!--          }}</span>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </client-only>-->
      </div>
    </client-only>
  </div>
</template>

<script>
import MarkerClusterer from '@google/markerclustererplus';
import { stylesData } from './map-window.js';
import { mapState } from 'vuex';
import SizeMixin from '@/mixins/size';

const CardListing = () => import('./info-window');
const SectionLoading = () => import('@/components/content-loading/section-loading.vue');
const SearchProfile = () => import('@/components/search/search-profile');
export default {
  mixins: [SizeMixin],
  props: ['listings', 'meta', 'mapTypeControl', 'isLoading'],
  components: { CardListing, SectionLoading, SearchProfile },
  computed: {
    ...mapState({
      // searchJson: state => state.v2.search.geoJson,
      showMap: state => state.global.showMap,
    }),
    listingNumber() {
      let leftNumber, rightNumber;
      if (this.meta.current_page === this.meta.total_pages) {
        leftNumber = this.meta.total - this.meta.count + 1;
        rightNumber = this.meta.total;
      } else {
        leftNumber = (this.meta.current_page - 1) * this.meta.count + 1;
        rightNumber = this.meta.current_page * this.meta.count;
      }
      if (this.meta.total > 1) {
        return `${leftNumber} - ${rightNumber}`;
      } else {
        return leftNumber;
      }
    },
  },
  data: () => ({
    center: {
      lat: 0.865451,
      lng: 113.550298,
    },
    map: '',
    clusterOptions: {
      maxZoom: 18,
      // imagePath: '/img/icon_pin_map/ic_m',
      styles: stylesData,
    },
    content: '',
    listingData: [],
    infoWindowCluster: null,
    infoWindowIn: false,
    clusterIn: false,
    markerIn: false,
    infoWindows: [],
    focusMarkerIndex: null,
    markers: [],
    markerCluster: null,
  }),
  watch: {
    listings(value) {
      if (value) {
        this.initMarker();
      }
    },
    showMap(value) {
      if (value) {
        setTimeout(() => {
          this.initMapBounds();
        }, 550);
      }
    },
    // contentMaps(val) {
    //   this.$nextTick(() => {
    //     if (val) {
    //       let listings = JSON.parse(JSON.stringify(this.listings));
    //       for (let i = 0; i < listings.length; i++) {
    //         // eslint-disable-next-line no-undef
    //         this.infoWindows[i] = new google.maps.InfoWindow({
    //           content: this.$refs.cardListing.$el,
    //         });
    //       }
    //     }
    //   });
    // },
  },
  methods: {
    isInfoWindowOpened(infoWindow) {
      const map_ = infoWindow.getMap();
      return map_ !== null && typeof map_ !== 'undefined';
    },

    infoWindowEnter() {
      this.infoWindowIn = true;
      this.checkCloseInfoWindowCluster();
    },
    infoWindowLeave() {
      this.infoWindowIn = false;
      this.checkCloseInfoWindowCluster();
      this.checkCloseInfoWindowMarker(this.focusMarkerIndex);
    },
    clusterEnter(cluster) {
      this.clusterIn = true;
      this.checkOpenInfoWindowCluster(cluster);
    },
    clusterLeave() {
      this.clusterIn = false;
      setTimeout(() => {
        this.checkCloseInfoWindowCluster();
      }, 200);
    },

    checkCloseInfoWindowCluster() {
      if (!this.infoWindowIn && !this.clusterIn && this.focusMarkerIndex === null) {
        this.listingData = [];
        this.infoWindowCluster.close();
      }
    },
    checkOpenInfoWindowCluster(cluster) {
      if (!this.isInfoWindowOpened(this.infoWindowCluster)) {
        // eslint-disable-next-line no-undef
        this.infoWindowCluster = new google.maps.InfoWindow({
          content: this.$refs.cardListing.$el,
        });
        this.infoWindowCluster.setPosition(cluster.getCenter());
        this.infoWindowCluster.open(this.map);
        this.getListingsByLatLng(cluster.getCenter().lat(), cluster.getCenter().lng());
      }
    },
    markerEnter(data, markerIndex, marker) {
      this.markerIn = true;
      this.checkOpenInfoWindowMarker(data, markerIndex, marker);
    },
    markerLeave(markerIndex) {
      this.markerIn = false;
      setTimeout(() => {
        this.checkCloseInfoWindowMarker(markerIndex);
      }, 200);
    },
    checkOpenInfoWindowMarker(data, markerIndex, marker) {
      if (!this.isInfoWindowOpened(this.infoWindows[markerIndex])) {
        this.focusMarkerIndex = markerIndex;
        this.listingData = [data];
        this.infoWindows[markerIndex].open(this.map, marker);
      }
    },
    checkCloseInfoWindowMarker(markerIndex) {
      if (!this.infoWindowIn && !this.markerIn && this.focusMarkerIndex !== null) {
        this.listingData = [];
        this.infoWindows[markerIndex].close();
        this.focusMarkerIndex = null;
      }
    },

    getListingsByLatLng(lat, lng) {
      const filterMarkers = this.listings.filter(listing => {
        return (
          Math.abs(listing.property.latlng.lat - lat) < 0.0001 &&
          Math.abs(listing.property.latlng.lng - lng) < 0.0001
        );
      });
      this.listingData = filterMarkers;
    },

    getWidth() {
      if (this.$refs.mapLayout) {
        let width = this.$refs.mapLayout.clientWidth;
        this.$store.commit('size/SET_MAP_WIDTH', width);
      } else {
        this.$store.commit('size/SET_MAP_WIDTH', null);
      }
    },
    // hideMaps() {
    //   this.$store.commit('size/SET_CLOSE_MAP', !this.contentMaps);
    // },
    async initMap() {
      try {
        let self = this;
        const element = document.getElementById('map');
        const options = {
          center: self.center,
          mapType: 'hybrid',
          zoom: 4,
          maxZoom: 18,
          zoomControl: true,
          zoomControlOptions: {
            // eslint-disable-next-line no-undef
            position: google.maps.ControlPosition.LEFT_TOP,
          },
          streetViewControl: false,
          mapTypeControl: self.mapTypeControl,
          gestureHandling: 'greedy',
        };
        // eslint-disable-next-line no-undef
        self.map = new google.maps.Map(element, options);

        // setTimeout(async () => {
        //   let query = self.$route.query;
        //   await self.$store.dispatch('v2/search/getGeoJsonListings', query);
        // }, 500);
      } catch (e) {
        console.log('ERROR INIT MAP GOOGLE MAPS: ', e);
      }
    },
    clearMarkers() {
      if (this.markerCluster !== null) {
        this.markers = [];
        this.markerCluster.clearMarkers();
      }
    },

    async initMarker() {
      try {
        let self = this;
        let icon = {
          url: '/img/map_pin.png', // url
          // eslint-disable-next-line no-undef
          scaledSize: new google.maps.Size(50, 50), // scaled size
          // eslint-disable-next-line no-undef
          origin: new google.maps.Point(0, 0), // origin
          // eslint-disable-next-line no-undef
          anchor: new google.maps.Point(25, 50), // anchor
        };
        this.clearMarkers();
        if (self.listings) {
          // var service = new google.maps.places.PlacesService(self.map);
          // var infoWindow = new google.maps.InfoWindow({ content:self.content,  maxWidth: 300});
          // eslint-disable-next-line no-undef
          this.infoWindowCluster = new google.maps.InfoWindow({
            content: self.content,
            maxWidth: 300,
          });
          self.listings.forEach((data, index) => {
            let latlng = data.property.latlng;
            // eslint-disable-next-line no-undef
            let marker = new google.maps.Marker({
              // eslint-disable-next-line no-undef
              position: new google.maps.LatLng(latlng.lat, latlng.lng),
              icon: icon,
              map: self.map,
              options: {
                uuid: data.listing_uuid,
              },
            });
            marker.addListener('click', function() {
              self.$router.push(data.links.detail);
            });

            if (self.$refs.cardListing)
              // eslint-disable-next-line no-undef
              this.infoWindows[index] = new google.maps.InfoWindow({
                content: self.$refs.cardListing.$el,
              });

            marker.addListener('mouseover', async () => {
              this.markerEnter(data, index, marker);
              // self.listingData = [data];
              // infoWindow[index].open(self.map, marker);
            });
            marker.addListener('mouseout', async () => {
              this.markerLeave(index);
              // self.listingData = [];
              // infoWindow[index].close();
            });
            self.markers.push(marker);
          });

          // eslint-disable-next-line no-undef
          google.maps.event.addListener(self.map, 'zoom_changed', function() {
            // let getBounds = self.map.getBounds();
            // let queryBounds = {
            //     swLat : getBounds.getSouthWest().lat(),
            //     swLng : getBounds.getSouthWest().lng(),
            //     neLat : getBounds.getNorthEast().lat(),
            //     neLng : getBounds.getNorthEast().lng(),
            // };
            //
            // self.$router.push({query:Object.assign({}, self.$route.query, queryBounds)})
          });

          self.markerCluster = new MarkerClusterer(self.map, self.markers, self.clusterOptions);

          // eslint-disable-next-line no-undef
          google.maps.event.addListener(self.markerCluster, 'mouseover', c => {
            if (this.map.getZoom() === self.markerCluster.getMaxZoom()) {
              this.clusterEnter(c);
            }
            // log("&mdash;Number of managed markers in cluster: " + c.getSize());
          });

          // eslint-disable-next-line
          google.maps.event.addListener(self.markerCluster, 'mouseout', c => {
            // this.listingData = [];
            // infoWindowCluster.close();
            this.clusterLeave();
          });

          // eslint-disable-next-line no-undef
          google.maps.event.addListener(self.map, 'clusterclick', function(cluster) {
            let indexCluster = cluster.clusterIcon_.index_;
            if (indexCluster === 1) {
              let getMarkers = cluster.getMarkers();
              let uuid = [];
              getMarkers.map(function(item) {
                return uuid.push(item.uuid);
              });
              // self.getListing(uuid)
            }
          });

          self.initMapBounds(false);
        }
      } catch (e) {
        console.log('ERROR INIT MARKER GOOGLE MAPS: ', e);
      }
    },
    initMapBounds(firstTime) {
      let self = this;
      // eslint-disable-next-line no-undef
      var bounds = new google.maps.LatLngBounds();
      self.listings.forEach(function(data) {
        let latlng = data.property.latlng;
        // eslint-disable-next-line no-undef
        bounds.extend(new google.maps.LatLng(latlng.lat, latlng.lng));
      });
      self.map.fitBounds(bounds);

      if (firstTime) {
        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(self.map, 'tilesloaded', function() {
          self.map.fitBounds(bounds);

          let listings = JSON.parse(JSON.stringify(self.listings));
          for (let i = 0; i < listings.length; i++) {
            // eslint-disable-next-line no-undef
            self.infoWindows[i] = new google.maps.InfoWindow({
              content: self.$refs.cardListing.$el,
            });
          }
        });
      }
    },
  },
  mounted() {
    let self = this;
    this.$nextTick(async () => {
      window.addEventListener('resize', self.getWidth);
      self.getWidth();
      self.initMap();
      self.initMapBounds(true);
      self.initMarker();
    });
  },
};
</script>

<style lang="scss" scoped>
.maps {
  bottom: 0;
}

.wrapper--switch {
  height: 100%;
  width: 100%;
}

.section--switch {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 75px;
  background-color: white;
  left: 0;
  top: 50%;
  border-radius: 0 5px 5px 0;
  cursor: pointer;

  &:hover {
    background-color: #ececec;
  }

  &.closed {
    transform: translateX(-100%) scaleX(-1);
    background-color: #0c83d3;

    span {
      color: white;
    }
  }
}
</style>
