// eslint-disable-next-line no-unused-vars
export function windowContent(listing) {
  let content = '<div></div>';
  // '<div class="card card--listing mh-400">'+
  //     '<div class="wrapper--img">'+
  //         '<img src="'+listing.primary_photo.url+'" alt="" width="100%"></div>'

  //     // '<div class="card-body">'+
  //     //     '<router-link :to="listing.links.detail" >'+
  //     //         '<div class="wrapper--title">'+
  //     //             '<h6>'+listing.listing_title+'</h6>'+
  //     //         '</div>'+
  //     //     '</router-link>'+
  //     //     '<div class="wrapper--info">'
  //     //         '<p>'+ listing.property.property_type_name+'</p></div>'

  //     //     '<div class="wrapper--price">'+
  //     //         '<h6 class="mb-0" v-for="'+price in listing.rent_prices+'" :key="'+price.id+'"><strong>'+price.price_terbilang +'</strong></h6>'
  //     //     '</div>'+
  //     // '</div>'+
  // '</div>';
  return content;
}

export let stylesData = [
  {
    width: 53,
    height: 53,
    textSize: 14,
    textColor: 'white',
    url: '/img/icon_pin_map/ic_m1.png',
  },
  {
    width: 56,
    height: 56,
    textSize: 14,
    textColor: 'white',
    url: '/img/icon_pin_map/ic_m2.png',
  },
  {
    width: 70,
    height: 70,
    textSize: 14,
    textColor: 'white',
    url: '/img/icon_pin_map/ic_m3.png',
  },
  {
    width: 103,
    height: 103,
    textSize: 14,
    textColor: 'white',
    url: '/img/icon_pin_map/ic_m4.png',
  },
  {
    width: 175,
    height: 175,
    textSize: 14,
    textColor: 'white',
    url: '/img/icon_pin_map/ic_m5.png',
  },
];
