import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
export default {
  data() {
    return {
      height: null,
      width: null,
    };
  },
  methods: {
    // eslint-disable-next-line no-undef
    getSize: debounce(function() {
      this.height = document.documentElement.clientHeight - 70;
      this.width = document.documentElement.clientWidth;
      this.$store.commit('size/SET_HEIGHT', this.height);
      this.$store.commit('size/SET_WIDTH', this.width);
    }, 200),
  },
  computed: {
    ...mapState({
      headerHeight: state => state.size.headerHeight,
    }),
  },
  mounted() {
    let self = this;
    this.$nextTick(function() {
      window.addEventListener('resize', self.getSize);
      self.getSize();
    });
  },
};
